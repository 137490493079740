import createLucideIcon from '../createLucideIcon';

const RouteOff = createLucideIcon('RouteOff', [
  ['circle', { cx: '6', cy: '19', r: '3', key: '1kj8tv' }],
  ['path', { d: 'M9 19h8.5c.4 0 .9-.1 1.3-.2', key: '1effex' }],
  ['path', { d: 'M5.2 5.2A3.5 3.53 0 0 0 6.5 12H12', key: 'k9y2ds' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  ['path', { d: 'M21 15.3a3.5 3.5 0 0 0-3.3-3.3', key: '11nlu2' }],
  ['path', { d: 'M15 5h-4.3', key: '6537je' }],
  ['circle', { cx: '18', cy: '5', r: '3', key: 'gq8acd' }],
]);

export default RouteOff;
