import createLucideIcon from '../createLucideIcon';

const LockKeyhole = createLucideIcon('LockKeyhole', [
  ['circle', { cx: '12', cy: '16', r: '1', key: '1au0dj' }],
  [
    'rect',
    { x: '3', y: '10', width: '18', height: '12', rx: '2', key: '6s8ecr' },
  ],
  ['path', { d: 'M7 10V7a5 5 0 0 1 10 0v3', key: '1pqi11' }],
]);

export default LockKeyhole;
