import createLucideIcon from '../createLucideIcon';

const ShieldEllipsis = createLucideIcon('ShieldEllipsis', [
  ['path', { d: 'M12 22s8-4 8-10V5l-8-3-8 3v7c0 6 8 10 8 10', key: '1irkt0' }],
  ['path', { d: 'M8 11h.01', key: '1dfujw' }],
  ['path', { d: 'M12 11h.01', key: 'z322tv' }],
  ['path', { d: 'M16 11h.01', key: 'xkw8gn' }],
]);

export default ShieldEllipsis;
