import createLucideIcon from '../createLucideIcon';

const Archive = createLucideIcon('Archive', [
  [
    'rect',
    { width: '20', height: '5', x: '2', y: '3', rx: '1', key: '1wp1u1' },
  ],
  ['path', { d: 'M4 8v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8', key: '1s80jp' }],
  ['path', { d: 'M10 12h4', key: 'a56b0p' }],
]);

export default Archive;
