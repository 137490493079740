import createLucideIcon from '../createLucideIcon';

const PlusSquare = createLucideIcon('PlusSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M8 12h8', key: '1wcyev' }],
  ['path', { d: 'M12 8v8', key: 'napkw2' }],
]);

export default PlusSquare;
