import createLucideIcon from '../createLucideIcon';

const Tent = createLucideIcon('Tent', [
  ['path', { d: 'M3.5 21 14 3', key: '1szst5' }],
  ['path', { d: 'M20.5 21 10 3', key: '1310c3' }],
  ['path', { d: 'M15.5 21 12 15l-3.5 6', key: '1ddtfw' }],
  ['path', { d: 'M2 21h20', key: '1nyx9w' }],
]);

export default Tent;
