import createLucideIcon from '../createLucideIcon';

const Triangle = createLucideIcon('Triangle', [
  [
    'path',
    {
      d: 'M13.73 4a2 2 0 0 0-3.46 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z',
      key: '14u9p9',
    },
  ],
]);

export default Triangle;
