import createLucideIcon from '../createLucideIcon';

const ScanEye = createLucideIcon('ScanEye', [
  ['path', { d: 'M3 7V5a2 2 0 0 1 2-2h2', key: 'aa7l1z' }],
  ['path', { d: 'M17 3h2a2 2 0 0 1 2 2v2', key: '4qcy5o' }],
  ['path', { d: 'M21 17v2a2 2 0 0 1-2 2h-2', key: '6vwrx8' }],
  ['path', { d: 'M7 21H5a2 2 0 0 1-2-2v-2', key: 'ioqczr' }],
  ['circle', { cx: '12', cy: '12', r: '1', key: '41hilf' }],
  ['path', { d: 'M5 12s2.5-5 7-5 7 5 7 5-2.5 5-7 5-7-5-7-5', key: 'nhuolu' }],
]);

export default ScanEye;
