import createLucideIcon from '../createLucideIcon';

const FolderSync = createLucideIcon('FolderSync', [
  [
    'path',
    {
      d: 'M9 20H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h3.9a2 2 0 0 1 1.69.9l.81 1.2a2 2 0 0 0 1.67.9H20a2 2 0 0 1 2 2v1',
      key: '1rkwto',
    },
  ],
  ['path', { d: 'M12 10v4h4', key: '1czhmt' }],
  [
    'path',
    {
      d: 'm12 14 1.5-1.5c.9-.9 2.2-1.5 3.5-1.5s2.6.6 3.5 1.5c.4.4.8 1 1 1.5',
      key: '25wejs',
    },
  ],
  ['path', { d: 'M22 22v-4h-4', key: '1ewp4q' }],
  [
    'path',
    {
      d: 'm22 18-1.5 1.5c-.9.9-2.1 1.5-3.5 1.5s-2.6-.6-3.5-1.5c-.4-.4-.8-1-1-1.5',
      key: 'vlp1j8',
    },
  ],
]);

export default FolderSync;
