import createLucideIcon from '../createLucideIcon';

const ArrowUpRightSquare = createLucideIcon('ArrowUpRightSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M8 8h8v8', key: 'b65dnt' }],
  ['path', { d: 'm8 16 8-8', key: '13b9ih' }],
]);

export default ArrowUpRightSquare;
