import createLucideIcon from '../createLucideIcon';

const BadgeSwissFranc = createLucideIcon('BadgeSwissFranc', [
  [
    'path',
    {
      d: 'M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z',
      key: '3c2336',
    },
  ],
  ['path', { d: 'M11 17V8h4', key: '1bfq6y' }],
  ['path', { d: 'M11 12h3', key: '2eqnfz' }],
  ['path', { d: 'M9 16h4', key: '1skf3a' }],
]);

export default BadgeSwissFranc;
