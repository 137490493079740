import createLucideIcon from '../createLucideIcon';

const MousePointerSquareDashed = createLucideIcon('MousePointerSquareDashed', [
  ['path', { d: 'M5 3a2 2 0 0 0-2 2', key: 'y57alp' }],
  ['path', { d: 'M19 3a2 2 0 0 1 2 2', key: '18rm91' }],
  ['path', { d: 'm12 12 4 10 1.7-4.3L22 16Z', key: '64ilsv' }],
  ['path', { d: 'M5 21a2 2 0 0 1-2-2', key: 'sbafld' }],
  ['path', { d: 'M9 3h1', key: '1yesri' }],
  ['path', { d: 'M9 21h2', key: '1qve2z' }],
  ['path', { d: 'M14 3h1', key: '1ec4yj' }],
  ['path', { d: 'M3 9v1', key: '1r0deq' }],
  ['path', { d: 'M21 9v2', key: 'p14lih' }],
  ['path', { d: 'M3 14v1', key: 'vnatye' }],
]);

export default MousePointerSquareDashed;
