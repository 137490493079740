import createLucideIcon from '../createLucideIcon';

const ArrowDownSquare = createLucideIcon('ArrowDownSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M12 8v8', key: 'napkw2' }],
  ['path', { d: 'm8 12 4 4 4-4', key: 'k98ssh' }],
]);

export default ArrowDownSquare;
