import createLucideIcon from '../createLucideIcon';

const ShoppingBag = createLucideIcon('ShoppingBag', [
  [
    'path',
    { d: 'M6 2 3 6v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V6l-3-4Z', key: 'hou9p0' },
  ],
  ['path', { d: 'M3 6h18', key: 'd0wm0j' }],
  ['path', { d: 'M16 10a4 4 0 0 1-8 0', key: '1ltviw' }],
]);

export default ShoppingBag;
