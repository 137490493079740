import createLucideIcon from '../createLucideIcon';

const BadgeJapaneseYen = createLucideIcon('BadgeJapaneseYen', [
  [
    'path',
    {
      d: 'M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z',
      key: '3c2336',
    },
  ],
  ['path', { d: 'm9 8 3 3v7', key: '17yadx' }],
  ['path', { d: 'm12 11 3-3', key: 'p4cfq1' }],
  ['path', { d: 'M9 12h6', key: '1c52cq' }],
  ['path', { d: 'M9 16h6', key: '8wimt3' }],
]);

export default BadgeJapaneseYen;
