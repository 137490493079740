import createLucideIcon from '../createLucideIcon';

const UserCog = createLucideIcon('UserCog', [
  ['circle', { cx: '18', cy: '15', r: '3', key: 'gjjjvw' }],
  ['circle', { cx: '9', cy: '7', r: '4', key: 'nufk8' }],
  ['path', { d: 'M10 15H6a4 4 0 0 0-4 4v2', key: '1nfge6' }],
  ['path', { d: 'm21.7 16.4-.9-.3', key: '12j9ji' }],
  ['path', { d: 'm15.2 13.9-.9-.3', key: '1fdjdi' }],
  ['path', { d: 'm16.6 18.7.3-.9', key: 'heedtr' }],
  ['path', { d: 'm19.1 12.2.3-.9', key: '1af3ki' }],
  ['path', { d: 'm19.6 18.7-.4-1', key: '1x9vze' }],
  ['path', { d: 'm16.8 12.3-.4-1', key: 'vqeiwj' }],
  ['path', { d: 'm14.3 16.6 1-.4', key: '1qlj63' }],
  ['path', { d: 'm20.7 13.8 1-.4', key: '1v5t8k' }],
]);

export default UserCog;
