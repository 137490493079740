import createLucideIcon from '../createLucideIcon';

const Truck = createLucideIcon('Truck', [
  [
    'path',
    {
      d: 'M5 18H3c-.6 0-1-.4-1-1V7c0-.6.4-1 1-1h10c.6 0 1 .4 1 1v11',
      key: 'hs4xqm',
    },
  ],
  ['path', { d: 'M14 9h4l4 4v4c0 .6-.4 1-1 1h-2', key: '11fp61' }],
  ['circle', { cx: '7', cy: '18', r: '2', key: '19iecd' }],
  ['path', { d: 'M15 18H9', key: '1lyqi6' }],
  ['circle', { cx: '17', cy: '18', r: '2', key: '332jqn' }],
]);

export default Truck;
