import createLucideIcon from '../createLucideIcon';

const CarFront = createLucideIcon('CarFront', [
  [
    'path',
    {
      d: 'm21 8-2 2-1.5-3.7A2 2 0 0 0 15.646 5H8.4a2 2 0 0 0-1.903 1.257L5 10 3 8',
      key: '1imjwt',
    },
  ],
  ['path', { d: 'M7 14h.01', key: '1qa3f1' }],
  ['path', { d: 'M17 14h.01', key: '7oqj8z' }],
  [
    'rect',
    { width: '18', height: '8', x: '3', y: '10', rx: '2', key: 'a7itu8' },
  ],
  ['path', { d: 'M5 18v2', key: 'ppbyun' }],
  ['path', { d: 'M19 18v2', key: 'gy7782' }],
]);

export default CarFront;
