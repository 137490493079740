import createLucideIcon from '../createLucideIcon';

const ScanBarcode = createLucideIcon('ScanBarcode', [
  ['path', { d: 'M3 7V5a2 2 0 0 1 2-2h2', key: 'aa7l1z' }],
  ['path', { d: 'M17 3h2a2 2 0 0 1 2 2v2', key: '4qcy5o' }],
  ['path', { d: 'M21 17v2a2 2 0 0 1-2 2h-2', key: '6vwrx8' }],
  ['path', { d: 'M7 21H5a2 2 0 0 1-2-2v-2', key: 'ioqczr' }],
  ['path', { d: 'M8 7v10', key: '23sfjj' }],
  ['path', { d: 'M12 7v10', key: 'jspqdw' }],
  ['path', { d: 'M17 7v10', key: '578dap' }],
]);

export default ScanBarcode;
