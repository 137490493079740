import createLucideIcon from '../createLucideIcon';

const ArrowLeftSquare = createLucideIcon('ArrowLeftSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'm12 8-4 4 4 4', key: '15vm53' }],
  ['path', { d: 'M16 12H8', key: '1fr5h0' }],
]);

export default ArrowLeftSquare;
