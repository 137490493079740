import createLucideIcon from '../createLucideIcon';

const LayoutList = createLucideIcon('LayoutList', [
  ['rect', { width: '7', height: '7', x: '3', y: '3', rx: '1', key: '1g98yp' }],
  [
    'rect',
    { width: '7', height: '7', x: '3', y: '14', rx: '1', key: '1bb6yr' },
  ],
  ['path', { d: 'M14 4h7', key: '3xa0d5' }],
  ['path', { d: 'M14 9h7', key: '1icrd9' }],
  ['path', { d: 'M14 15h7', key: '1mj8o2' }],
  ['path', { d: 'M14 20h7', key: '11slyb' }],
]);

export default LayoutList;
