import createLucideIcon from '../createLucideIcon';

const Bus = createLucideIcon('Bus', [
  ['path', { d: 'M8 6v6', key: '18i7km' }],
  ['path', { d: 'M15 6v6', key: '1sg6z9' }],
  ['path', { d: 'M2 12h19.6', key: 'de5uta' }],
  [
    'path',
    {
      d: 'M18 18h3s.5-1.7.8-2.8c.1-.4.2-.8.2-1.2 0-.4-.1-.8-.2-1.2l-1.4-5C20.1 6.8 19.1 6 18 6H4a2 2 0 0 0-2 2v10h3',
      key: '1wwztk',
    },
  ],
  ['circle', { cx: '7', cy: '18', r: '2', key: '19iecd' }],
  ['path', { d: 'M9 18h5', key: 'lrx6i' }],
  ['circle', { cx: '16', cy: '18', r: '2', key: '1v4tcr' }],
]);

export default Bus;
