import createLucideIcon from '../createLucideIcon';

const SquareCode = createLucideIcon('SquareCode', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'm10 10-2 2 2 2', key: 'p6et6i' }],
  ['path', { d: 'm14 14 2-2-2-2', key: 'm075q2' }],
]);

export default SquareCode;
