import createLucideIcon from '../createLucideIcon';

const CloudCog = createLucideIcon('CloudCog', [
  ['circle', { cx: '12', cy: '17', r: '3', key: '1spfwm' }],
  [
    'path',
    {
      d: 'M4.2 15.1A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.5 8.2',
      key: 'zaobp',
    },
  ],
  ['path', { d: 'm15.7 18.4-.9-.3', key: '4qxpbn' }],
  ['path', { d: 'm9.2 15.9-.9-.3', key: '17q7o2' }],
  ['path', { d: 'm10.6 20.7.3-.9', key: '1pf4s2' }],
  ['path', { d: 'm13.1 14.2.3-.9', key: '1mnuqm' }],
  ['path', { d: 'm13.6 20.7-.4-1', key: '1jpd1m' }],
  ['path', { d: 'm10.8 14.3-.4-1', key: '17ugyy' }],
  ['path', { d: 'm8.3 18.6 1-.4', key: 's42vdx' }],
  ['path', { d: 'm14.7 15.8 1-.4', key: '2wizun' }],
]);

export default CloudCog;
