import createLucideIcon from '../createLucideIcon';

const HardDriveDownload = createLucideIcon('HardDriveDownload', [
  ['path', { d: 'M12 2v8', key: '1q4o3n' }],
  ['path', { d: 'm16 6-4 4-4-4', key: '6wukr' }],
  [
    'rect',
    { width: '20', height: '8', x: '2', y: '14', rx: '2', key: 'w68u3i' },
  ],
  ['path', { d: 'M6 18h.01', key: 'uhywen' }],
  ['path', { d: 'M10 18h.01', key: 'h775k' }],
]);

export default HardDriveDownload;
