import createLucideIcon from '../createLucideIcon';

const ServerCog = createLucideIcon('ServerCog', [
  ['circle', { cx: '12', cy: '12', r: '3', key: '1v7zrd' }],
  [
    'path',
    {
      d: 'M4.5 10H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2h-.5',
      key: 'tn8das',
    },
  ],
  [
    'path',
    {
      d: 'M4.5 14H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2v-4a2 2 0 0 0-2-2h-.5',
      key: '1g2pve',
    },
  ],
  ['path', { d: 'M6 6h.01', key: '1utrut' }],
  ['path', { d: 'M6 18h.01', key: 'uhywen' }],
  ['path', { d: 'm15.7 13.4-.9-.3', key: '1jwmzr' }],
  ['path', { d: 'm9.2 10.9-.9-.3', key: 'qapnim' }],
  ['path', { d: 'm10.6 15.7.3-.9', key: 'quwk0k' }],
  ['path', { d: 'm13.6 15.7-.4-1', key: 'cb9xp7' }],
  ['path', { d: 'm10.8 9.3-.4-1', key: '1uaiz5' }],
  ['path', { d: 'm8.3 13.6 1-.4', key: 's6srou' }],
  ['path', { d: 'm14.7 10.8 1-.4', key: '4d31cq' }],
  ['path', { d: 'm13.4 8.3-.3.9', key: '1bm987' }],
]);

export default ServerCog;
