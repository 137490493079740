import createLucideIcon from '../createLucideIcon';

const MapPinned = createLucideIcon('MapPinned', [
  [
    'path',
    { d: 'M18 8c0 4.5-6 9-6 9s-6-4.5-6-9a6 6 0 0 1 12 0', key: 'yrbn30' },
  ],
  ['circle', { cx: '12', cy: '8', r: '2', key: '1822b1' }],
  [
    'path',
    {
      d: 'M8.835 14H5a1 1 0 0 0-.9.7l-2 6c-.1.1-.1.2-.1.3 0 .6.4 1 1 1h18c.6 0 1-.4 1-1 0-.1 0-.2-.1-.3l-2-6a1 1 0 0 0-.9-.7h-3.835',
      key: '112zkj',
    },
  ],
]);

export default MapPinned;
