import createLucideIcon from '../createLucideIcon';

const CircleDotDashed = createLucideIcon('CircleDotDashed', [
  ['path', { d: 'M10.1 2.18a9.93 9.93 0 0 1 3.8 0', key: '1qdqn0' }],
  ['path', { d: 'M17.6 3.71a9.95 9.95 0 0 1 2.69 2.7', key: '1bq7p6' }],
  ['path', { d: 'M21.82 10.1a9.93 9.93 0 0 1 0 3.8', key: '1rlaqf' }],
  ['path', { d: 'M20.29 17.6a9.95 9.95 0 0 1-2.7 2.69', key: '1xk03u' }],
  ['path', { d: 'M13.9 21.82a9.94 9.94 0 0 1-3.8 0', key: 'l7re25' }],
  ['path', { d: 'M6.4 20.29a9.95 9.95 0 0 1-2.69-2.7', key: '1v18p6' }],
  ['path', { d: 'M2.18 13.9a9.93 9.93 0 0 1 0-3.8', key: 'xdo6bj' }],
  ['path', { d: 'M3.71 6.4a9.95 9.95 0 0 1 2.7-2.69', key: '1jjmaz' }],
  ['circle', { cx: '12', cy: '12', r: '1', key: '41hilf' }],
]);

export default CircleDotDashed;
