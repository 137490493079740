import createLucideIcon from '../createLucideIcon';

const HdmiPort = createLucideIcon('HdmiPort', [
  [
    'path',
    {
      d: 'M22 9a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h1l2 2h12l2-2h1a1 1 0 0 0 1-1Z',
      key: '2128wb',
    },
  ],
  ['path', { d: 'M7.5 12h9', key: '1t0ckc' }],
]);

export default HdmiPort;
