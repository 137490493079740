import createLucideIcon from '../createLucideIcon';

const ArrowDownLeftSquare = createLucideIcon('ArrowDownLeftSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'm16 8-8 8', key: '166keh' }],
  ['path', { d: 'M16 16H8V8', key: '1w2ppm' }],
]);

export default ArrowDownLeftSquare;
