import createLucideIcon from '../createLucideIcon';

const TableProperties = createLucideIcon('TableProperties', [
  ['path', { d: 'M15 3v18', key: '14nvp0' }],
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M21 9H3', key: '1338ky' }],
  ['path', { d: 'M21 15H3', key: '9uk58r' }],
]);

export default TableProperties;
