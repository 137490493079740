import createLucideIcon from '../createLucideIcon';

const MSquare = createLucideIcon('MSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M8 16V8l4 4 4-4v8', key: '141u4e' }],
]);

export default MSquare;
