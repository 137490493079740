import createLucideIcon from '../createLucideIcon';

const Antenna = createLucideIcon('Antenna', [
  ['path', { d: 'M2 12 7 2', key: '117k30' }],
  ['path', { d: 'm7 12 5-10', key: '1tvx22' }],
  ['path', { d: 'm12 12 5-10', key: 'ev1o1a' }],
  ['path', { d: 'm17 12 5-10', key: '1e4ti3' }],
  ['path', { d: 'M4.5 7h15', key: 'vlsxkz' }],
  ['path', { d: 'M12 16v6', key: 'c8a4gj' }],
]);

export default Antenna;
