import createLucideIcon from '../createLucideIcon';

const ActivitySquare = createLucideIcon('ActivitySquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M17 12h-2l-2 5-2-10-2 5H7', key: '15hlnc' }],
]);

export default ActivitySquare;
