import createLucideIcon from '../createLucideIcon';

const CassetteTape = createLucideIcon('CassetteTape', [
  [
    'rect',
    { width: '20', height: '16', x: '2', y: '4', rx: '2', key: '18n3k1' },
  ],
  ['circle', { cx: '8', cy: '10', r: '2', key: '1xl4ub' }],
  ['path', { d: 'M8 12h8', key: '1wcyev' }],
  ['circle', { cx: '16', cy: '10', r: '2', key: 'r14t7q' }],
  [
    'path',
    {
      d: 'm6 20 .7-2.9A1.4 1.4 0 0 1 8.1 16h7.8a1.4 1.4 0 0 1 1.4 1l.7 3',
      key: 'l01ucn',
    },
  ],
]);

export default CassetteTape;
