import createLucideIcon from '../createLucideIcon';

const ListTodo = createLucideIcon('ListTodo', [
  ['rect', { x: '3', y: '5', width: '6', height: '6', rx: '1', key: '1defrl' }],
  ['path', { d: 'm3 17 2 2 4-4', key: '1jhpwq' }],
  ['path', { d: 'M13 6h8', key: '15sg57' }],
  ['path', { d: 'M13 12h8', key: 'h98zly' }],
  ['path', { d: 'M13 18h8', key: 'oe0vm4' }],
]);

export default ListTodo;
