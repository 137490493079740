import createLucideIcon from '../createLucideIcon';

const ScissorsLineDashed = createLucideIcon('ScissorsLineDashed', [
  ['path', { d: 'M5.42 9.42 8 12', key: '12pkuq' }],
  ['circle', { cx: '4', cy: '8', r: '2', key: '107mxr' }],
  ['path', { d: 'm14 6-8.58 8.58', key: 'gvzu5l' }],
  ['circle', { cx: '4', cy: '16', r: '2', key: '1ehqvc' }],
  ['path', { d: 'M10.8 14.8 14 18', key: 'ax7m9r' }],
  ['path', { d: 'M16 12h-2', key: '10asgb' }],
  ['path', { d: 'M22 12h-2', key: '14jgyd' }],
]);

export default ScissorsLineDashed;
