import createLucideIcon from '../createLucideIcon';

const Pencil = createLucideIcon('Pencil', [
  [
    'path',
    { d: 'M17 3a2.85 2.83 0 1 1 4 4L7.5 20.5 2 22l1.5-5.5Z', key: '5qss01' },
  ],
  ['path', { d: 'm15 5 4 4', key: '1mk7zo' }],
]);

export default Pencil;
