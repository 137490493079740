import createLucideIcon from '../createLucideIcon';

const XOctagon = createLucideIcon('XOctagon', [
  [
    'polygon',
    {
      points:
        '7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2',
      key: 'h1p8hx',
    },
  ],
  ['path', { d: 'm15 9-6 6', key: '1uzhvr' }],
  ['path', { d: 'm9 9 6 6', key: 'z0biqf' }],
]);

export default XOctagon;
