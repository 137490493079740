import createLucideIcon from '../createLucideIcon';

const Shapes = createLucideIcon('Shapes', [
  [
    'path',
    {
      d: 'M8.3 10a.7.7 0 0 1-.626-1.079L11.4 3a.7.7 0 0 1 1.198-.043L16.3 8.9a.7.7 0 0 1-.572 1.1Z',
      key: '1bo67w',
    },
  ],
  [
    'rect',
    { x: '3', y: '14', width: '7', height: '7', rx: '1', key: '1bkyp8' },
  ],
  ['circle', { cx: '17.5', cy: '17.5', r: '3.5', key: 'w3z12y' }],
]);

export default Shapes;
