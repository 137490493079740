import createLucideIcon from '../createLucideIcon';

const BadgeIndianRupee = createLucideIcon('BadgeIndianRupee', [
  [
    'path',
    {
      d: 'M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z',
      key: '3c2336',
    },
  ],
  ['path', { d: 'M8 8h8', key: '1bis0t' }],
  ['path', { d: 'M8 12h8', key: '1wcyev' }],
  ['path', { d: 'm13 17-5-1h1a4 4 0 0 0 0-8', key: 'nu2bwa' }],
]);

export default BadgeIndianRupee;
