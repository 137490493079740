import createLucideIcon from '../createLucideIcon';

const SunMoon = createLucideIcon('SunMoon', [
  ['path', { d: 'M12 8a2.83 2.83 0 0 0 4 4 4 4 0 1 1-4-4', key: '1fu5g2' }],
  ['path', { d: 'M12 2v2', key: 'tus03m' }],
  ['path', { d: 'M12 20v2', key: '1lh1kg' }],
  ['path', { d: 'm4.9 4.9 1.4 1.4', key: 'b9915j' }],
  ['path', { d: 'm17.7 17.7 1.4 1.4', key: 'qc3ed3' }],
  ['path', { d: 'M2 12h2', key: '1t8f8n' }],
  ['path', { d: 'M20 12h2', key: '1q8mjw' }],
  ['path', { d: 'm6.3 17.7-1.4 1.4', key: '5gca6' }],
  ['path', { d: 'm19.1 4.9-1.4 1.4', key: 'wpu9u6' }],
]);

export default SunMoon;
