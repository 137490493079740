import createLucideIcon from '../createLucideIcon';

const BrainCog = createLucideIcon('BrainCog', [
  ['circle', { cx: '12', cy: '12', r: '3', key: '1v7zrd' }],
  [
    'path',
    {
      d: 'M12 4.5a2.5 2.5 0 0 0-4.96-.46 2.5 2.5 0 0 0-1.98 3 2.5 2.5 0 0 0-1.32 4.24 3 3 0 0 0 .34 5.58 2.5 2.5 0 0 0 2.96 3.08A2.5 2.5 0 0 0 12 19.5a2.5 2.5 0 0 0 4.96.44 2.5 2.5 0 0 0 2.96-3.08 3 3 0 0 0 .34-5.58 2.5 2.5 0 0 0-1.32-4.24 2.5 2.5 0 0 0-1.98-3A2.5 2.5 0 0 0 12 4.5',
      key: '1f4le0',
    },
  ],
  ['path', { d: 'm15.7 10.4-.9.4', key: 'ayzo6p' }],
  ['path', { d: 'm9.2 13.2-.9.4', key: '1uzb3g' }],
  ['path', { d: 'm13.6 15.7-.4-.9', key: '11ifqf' }],
  ['path', { d: 'm10.8 9.2-.4-.9', key: '1pmk2v' }],
  ['path', { d: 'm15.7 13.5-.9-.4', key: '7ng02m' }],
  ['path', { d: 'm9.2 10.9-.9-.4', key: '1x66zd' }],
  ['path', { d: 'm10.5 15.7.4-.9', key: '3js94g' }],
  ['path', { d: 'm13.1 9.2.4-.9', key: '18n7mc' }],
]);

export default BrainCog;
