import createLucideIcon from '../createLucideIcon';

const Pentagon = createLucideIcon('Pentagon', [
  [
    'path',
    {
      d: 'M3.5 8.7c-.7.5-1 1.4-.7 2.2l2.8 8.7c.3.8 1 1.4 1.9 1.4h9.1c.9 0 1.6-.6 1.9-1.4l2.8-8.7c.3-.8 0-1.7-.7-2.2l-7.4-5.3a2.1 2.1 0 0 0-2.4 0Z',
      key: 'hsj90r',
    },
  ],
]);

export default Pentagon;
