import createLucideIcon from '../createLucideIcon';

const ImageDown = createLucideIcon('ImageDown', [
  ['circle', { cx: '9', cy: '9', r: '2', key: 'af1f0g' }],
  [
    'path',
    {
      d: 'M10.3 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v10.8',
      key: 'sqts6f',
    },
  ],
  ['path', { d: 'm21 15-3.1-3.1a2 2 0 0 0-2.814.014L6 21', key: '1h47z9' }],
  ['path', { d: 'm14 19.5 3 3v-6', key: '1x9jmo' }],
  ['path', { d: 'm17 22.5 3-3', key: 'xzuz0n' }],
]);

export default ImageDown;
