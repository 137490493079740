import createLucideIcon from '../createLucideIcon';

const ChevronUpSquare = createLucideIcon('ChevronUpSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'm8 14 4-4 4 4', key: 'fy2ptz' }],
]);

export default ChevronUpSquare;
