import createLucideIcon from '../createLucideIcon';

const Barcode = createLucideIcon('Barcode', [
  ['path', { d: 'M3 5v14', key: '1nt18q' }],
  ['path', { d: 'M8 5v14', key: '1ybrkv' }],
  ['path', { d: 'M12 5v14', key: 's699le' }],
  ['path', { d: 'M17 5v14', key: 'ycjyhj' }],
  ['path', { d: 'M21 5v14', key: 'nzette' }],
]);

export default Barcode;
