import createLucideIcon from '../createLucideIcon';

const DatabaseZap = createLucideIcon('DatabaseZap', [
  ['ellipse', { cx: '12', cy: '5', rx: '9', ry: '3', key: 'msslwz' }],
  ['path', { d: 'M3 5V19A9 3 0 0 0 15 21.84', key: '14ibmq' }],
  ['path', { d: 'M21 5V8', key: '1marbg' }],
  ['path', { d: 'M21 12L18 17H22L19 22', key: 'zafso' }],
  ['path', { d: 'M3 12A9 3 0 0 0 14.59 14.87', key: '1y4wr8' }],
]);

export default DatabaseZap;
