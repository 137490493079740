import createLucideIcon from '../createLucideIcon';

const KanbanSquare = createLucideIcon('KanbanSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M8 7v7', key: '1x2jlm' }],
  ['path', { d: 'M12 7v4', key: 'xawao1' }],
  ['path', { d: 'M16 7v9', key: '1hp2iy' }],
]);

export default KanbanSquare;
