import createLucideIcon from '../createLucideIcon';

const Tangent = createLucideIcon('Tangent', [
  ['circle', { cx: '17', cy: '4', r: '2', key: 'y5j2s2' }],
  ['path', { d: 'M15.59 5.41 5.41 15.59', key: 'l0vprr' }],
  ['circle', { cx: '4', cy: '17', r: '2', key: '9p4efm' }],
  ['path', { d: 'M12 22s-4-9-1.5-11.5S22 12 22 12', key: '1twk4o' }],
]);

export default Tangent;
