import createLucideIcon from '../createLucideIcon';

const Clapperboard = createLucideIcon('Clapperboard', [
  [
    'path',
    {
      d: 'M20.2 6 3 11l-.9-2.4c-.3-1.1.3-2.2 1.3-2.5l13.5-4c1.1-.3 2.2.3 2.5 1.3Z',
      key: '1tn4o7',
    },
  ],
  ['path', { d: 'm6.2 5.3 3.1 3.9', key: 'iuk76l' }],
  ['path', { d: 'm12.4 3.4 3.1 4', key: '6hsd6n' }],
  ['path', { d: 'M3 11h18v8a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2Z', key: 'ltgou9' }],
]);

export default Clapperboard;
