import createLucideIcon from '../createLucideIcon';

const Bug = createLucideIcon('Bug', [
  ['path', { d: 'm8 2 1.88 1.88', key: 'fmnt4t' }],
  ['path', { d: 'M14.12 3.88 16 2', key: 'qol33r' }],
  ['path', { d: 'M9 7.13v-1a3.003 3.003 0 1 1 6 0v1', key: 'd7y7pr' }],
  [
    'path',
    {
      d: 'M12 20c-3.3 0-6-2.7-6-6v-3a4 4 0 0 1 4-4h4a4 4 0 0 1 4 4v3c0 3.3-2.7 6-6 6',
      key: 'xs1cw7',
    },
  ],
  ['path', { d: 'M12 20v-9', key: '1qisl0' }],
  ['path', { d: 'M6.53 9C4.6 8.8 3 7.1 3 5', key: '32zzws' }],
  ['path', { d: 'M6 13H2', key: '82j7cp' }],
  ['path', { d: 'M3 21c0-2.1 1.7-3.9 3.8-4', key: '4p0ekp' }],
  ['path', { d: 'M20.97 5c0 2.1-1.6 3.8-3.5 4', key: '18gb23' }],
  ['path', { d: 'M22 13h-4', key: '1jl80f' }],
  ['path', { d: 'M17.2 17c2.1.1 3.8 1.9 3.8 4', key: 'k3fwyw' }],
]);

export default Bug;
