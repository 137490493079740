import createLucideIcon from '../createLucideIcon';

const CandyCane = createLucideIcon('CandyCane', [
  [
    'path',
    {
      d: 'M5.7 21a2 2 0 0 1-3.5-2l8.6-14a6 6 0 0 1 10.4 6 2 2 0 1 1-3.464-2 2 2 0 1 0-3.464-2Z',
      key: 'isaq8g',
    },
  ],
  ['path', { d: 'M17.75 7 15 2.1', key: '12x7e8' }],
  ['path', { d: 'M10.9 4.8 13 9', key: '100a87' }],
  ['path', { d: 'm7.9 9.7 2 4.4', key: 'ntfhaj' }],
  ['path', { d: 'M4.9 14.7 7 18.9', key: '1x43jy' }],
]);

export default CandyCane;
