import createLucideIcon from '../createLucideIcon';

const Cable = createLucideIcon('Cable', [
  ['path', { d: 'M4 9a2 2 0 0 1-2-2V5h6v2a2 2 0 0 1-2 2Z', key: '1s6oa5' }],
  ['path', { d: 'M3 5V3', key: '1k5hjh' }],
  ['path', { d: 'M7 5V3', key: '1t1388' }],
  [
    'path',
    { d: 'M19 15V6.5a3.5 3.5 0 0 0-7 0v11a3.5 3.5 0 0 1-7 0V9', key: '1ytv72' },
  ],
  ['path', { d: 'M17 21v-2', key: 'ds4u3f' }],
  ['path', { d: 'M21 21v-2', key: 'eo0ou' }],
  ['path', { d: 'M22 19h-6v-2a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2Z', key: 'sdz6o8' }],
]);

export default Cable;
