import createLucideIcon from '../createLucideIcon';

const Diameter = createLucideIcon('Diameter', [
  ['circle', { cx: '19', cy: '19', r: '2', key: '17f5cg' }],
  ['circle', { cx: '5', cy: '5', r: '2', key: '1gwv83' }],
  ['path', { d: 'M6.48 3.66a10 10 0 0 1 13.86 13.86', key: 'xr8kdq' }],
  ['path', { d: 'm6.41 6.41 11.18 11.18', key: 'uhpjw7' }],
  ['path', { d: 'M3.66 6.48a10 10 0 0 0 13.86 13.86', key: 'cldpwv' }],
]);

export default Diameter;
