import createLucideIcon from '../createLucideIcon';

const DatabaseBackup = createLucideIcon('DatabaseBackup', [
  ['ellipse', { cx: '12', cy: '5', rx: '9', ry: '3', key: 'msslwz' }],
  ['path', { d: 'M3 12a9 3 0 0 0 5 2.69', key: '1ui2ym' }],
  ['path', { d: 'M21 9.3V5', key: '6k6cib' }],
  ['path', { d: 'M3 5v14a9 3 0 0 0 6.47 2.88', key: 'i62tjy' }],
  ['path', { d: 'M12 12v4h4', key: '1bxaet' }],
  [
    'path',
    {
      d: 'M13 20a5 5 0 0 0 9-3 4.5 4.5 0 0 0-4.5-4.5c-1.33 0-2.54.54-3.41 1.41L12 16',
      key: '1f4ei9',
    },
  ],
]);

export default DatabaseBackup;
