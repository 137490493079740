import createLucideIcon from '../createLucideIcon';

const PencilLine = createLucideIcon('PencilLine', [
  ['path', { d: 'M12 20h9', key: 't2du7b' }],
  [
    'path',
    { d: 'M16.5 3.5a2.12 2.12 0 0 1 3 3L7 19l-4 1 1-4Z', key: 'ymcmye' },
  ],
  ['path', { d: 'm15 5 3 3', key: '1w25hb' }],
]);

export default PencilLine;
