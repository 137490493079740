import createLucideIcon from '../createLucideIcon';

const Theater = createLucideIcon('Theater', [
  ['path', { d: 'M2 10s3-3 3-8', key: '3xiif0' }],
  ['path', { d: 'M22 10s-3-3-3-8', key: 'ioaa5q' }],
  ['path', { d: 'M10 2c0 4.4-3.6 8-8 8', key: '16fkpi' }],
  ['path', { d: 'M14 2c0 4.4 3.6 8 8 8', key: 'b9eulq' }],
  ['path', { d: 'M2 10s2 2 2 5', key: '1au1lb' }],
  ['path', { d: 'M22 10s-2 2-2 5', key: 'qi2y5e' }],
  ['path', { d: 'M8 15h8', key: '45n4r' }],
  ['path', { d: 'M2 22v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1', key: '1vsc2m' }],
  ['path', { d: 'M14 22v-1a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1', key: 'hrha4u' }],
]);

export default Theater;
