import createLucideIcon from '../createLucideIcon';

const MousePointerClick = createLucideIcon('MousePointerClick', [
  ['path', { d: 'm9 9 5 12 1.8-5.2L21 14Z', key: '1b76lo' }],
  ['path', { d: 'M7.2 2.2 8 5.1', key: '1cfko1' }],
  ['path', { d: 'm5.1 8-2.9-.8', key: '1go3kf' }],
  ['path', { d: 'M14 4.1 12 6', key: 'ita8i4' }],
  ['path', { d: 'm6 12-1.9 2', key: 'mnht97' }],
]);

export default MousePointerClick;
