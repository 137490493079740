import createLucideIcon from '../createLucideIcon';

const BugPlay = createLucideIcon('BugPlay', [
  ['path', { d: 'm8 2 1.88 1.88', key: 'fmnt4t' }],
  ['path', { d: 'M14.12 3.88 16 2', key: 'qol33r' }],
  ['path', { d: 'M9 7.13v-1a3.003 3.003 0 1 1 6 0v1', key: 'd7y7pr' }],
  [
    'path',
    {
      d: 'M18 11a4 4 0 0 0-4-4h-4a4 4 0 0 0-4 4v3a6.1 6.1 0 0 0 2 4.5',
      key: '1tjixy',
    },
  ],
  ['path', { d: 'M6.53 9C4.6 8.8 3 7.1 3 5', key: '32zzws' }],
  ['path', { d: 'M6 13H2', key: '82j7cp' }],
  ['path', { d: 'M3 21c0-2.1 1.7-3.9 3.8-4', key: '4p0ekp' }],
  ['path', { d: 'M20.97 5c0 2.1-1.6 3.8-3.5 4', key: '18gb23' }],
  ['path', { d: 'm12 12 8 5-8 5Z', key: '1ydf81' }],
]);

export default BugPlay;
