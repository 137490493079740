import createLucideIcon from '../createLucideIcon';

const BookmarkCheck = createLucideIcon('BookmarkCheck', [
  [
    'path',
    { d: 'm19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2Z', key: '169p4p' },
  ],
  ['path', { d: 'm9 10 2 2 4-4', key: '1gnqz4' }],
]);

export default BookmarkCheck;
