import createLucideIcon from '../createLucideIcon';

const ShoppingBasket = createLucideIcon('ShoppingBasket', [
  ['path', { d: 'm5 11 4-7', key: '116ra9' }],
  ['path', { d: 'm19 11-4-7', key: 'cnml18' }],
  ['path', { d: 'M2 11h20', key: '3eubbj' }],
  [
    'path',
    {
      d: 'm3.5 11 1.6 7.4a2 2 0 0 0 2 1.6h9.8c.9 0 1.8-.7 2-1.6l1.7-7.4',
      key: '1x2lvw',
    },
  ],
  ['path', { d: 'm9 11 1 9', key: '1ojof7' }],
  ['path', { d: 'M4.5 15.5h15', key: '13mye1' }],
  ['path', { d: 'm15 11-1 9', key: '5wnq3a' }],
]);

export default ShoppingBasket;
