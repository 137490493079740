import createLucideIcon from '../createLucideIcon';

const TrainFront = createLucideIcon('TrainFront', [
  ['path', { d: 'M8 3.1V7a4 4 0 0 0 8 0V3.1', key: '1v71zp' }],
  ['path', { d: 'm9 15-1-1', key: '1yrq24' }],
  ['path', { d: 'm15 15 1-1', key: '1t0d6s' }],
  [
    'path',
    {
      d: 'M9 19c-2.8 0-5-2.2-5-5v-4a8 8 0 0 1 16 0v4c0 2.8-2.2 5-5 5Z',
      key: '1p0hjs',
    },
  ],
  ['path', { d: 'm8 19-2 3', key: '13i0xs' }],
  ['path', { d: 'm16 19 2 3', key: 'xo31yx' }],
]);

export default TrainFront;
