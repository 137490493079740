import createLucideIcon from '../createLucideIcon';

const ShieldOff = createLucideIcon('ShieldOff', [
  ['path', { d: 'M19.7 14a6.9 6.9 0 0 0 .3-2V5l-8-3-3.2 1.2', key: '342pvf' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  [
    'path',
    {
      d: 'M4.7 4.7 4 5v7c0 6 8 10 8 10a20.3 20.3 0 0 0 5.62-4.38',
      key: 'p0ycf4',
    },
  ],
]);

export default ShieldOff;
