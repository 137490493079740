import createLucideIcon from '../createLucideIcon';

const Grid2x2 = createLucideIcon('Grid2x2', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M3 12h18', key: '1i2n21' }],
  ['path', { d: 'M12 3v18', key: '108xh3' }],
]);

export default Grid2x2;
