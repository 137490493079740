import createLucideIcon from '../createLucideIcon';

const Binary = createLucideIcon('Binary', [
  [
    'rect',
    { x: '14', y: '14', width: '4', height: '6', rx: '2', key: 'p02svl' },
  ],
  ['rect', { x: '6', y: '4', width: '4', height: '6', rx: '2', key: 'xm4xkj' }],
  ['path', { d: 'M6 20h4', key: '1i6q5t' }],
  ['path', { d: 'M14 10h4', key: 'ru81e7' }],
  ['path', { d: 'M6 14h2v6', key: '16z9wg' }],
  ['path', { d: 'M14 4h2v6', key: '1idq9u' }],
]);

export default Binary;
