import createLucideIcon from '../createLucideIcon';

const Tally5 = createLucideIcon('Tally5', [
  ['path', { d: 'M4 4v16', key: '6qkkli' }],
  ['path', { d: 'M9 4v16', key: '81ygyz' }],
  ['path', { d: 'M14 4v16', key: '12vmem' }],
  ['path', { d: 'M19 4v16', key: '8ij5ei' }],
  ['path', { d: 'M22 6 2 18', key: 'h9moai' }],
]);

export default Tally5;
