import createLucideIcon from '../createLucideIcon';

const Scissors = createLucideIcon('Scissors', [
  ['circle', { cx: '6', cy: '6', r: '3', key: '1lh9wr' }],
  ['path', { d: 'M8.12 8.12 12 12', key: '1alkpv' }],
  ['path', { d: 'M20 4 8.12 15.88', key: 'xgtan2' }],
  ['circle', { cx: '6', cy: '18', r: '3', key: 'fqmcym' }],
  ['path', { d: 'M14.8 14.8 20 20', key: 'ptml3r' }],
]);

export default Scissors;
