import createLucideIcon from '../createLucideIcon';

const GanttChartSquare = createLucideIcon('GanttChartSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M9 8h7', key: 'kbo1nt' }],
  ['path', { d: 'M8 12h6', key: 'ikassy' }],
  ['path', { d: 'M11 16h5', key: 'oq65wt' }],
]);

export default GanttChartSquare;
