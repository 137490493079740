import createLucideIcon from '../createLucideIcon';

const SquareDashedBottomCode = createLucideIcon('SquareDashedBottomCode', [
  ['path', { d: 'm10 10-2 2 2 2', key: 'p6et6i' }],
  ['path', { d: 'm14 14 2-2-2-2', key: 'm075q2' }],
  [
    'path',
    {
      d: 'M5 21a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2',
      key: 'as5y1o',
    },
  ],
  ['path', { d: 'M9 21h1', key: '15o7lz' }],
  ['path', { d: 'M14 21h1', key: 'v9vybs' }],
]);

export default SquareDashedBottomCode;
