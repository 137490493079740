import createLucideIcon from '../createLucideIcon';

const PackagePlus = createLucideIcon('PackagePlus', [
  ['path', { d: 'M16 16h6', key: '100bgy' }],
  ['path', { d: 'M19 13v6', key: '85cyf1' }],
  [
    'path',
    {
      d: 'M21 10V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l2-1.14',
      key: 'e7tb2h',
    },
  ],
  ['path', { d: 'm7.5 4.27 9 5.15', key: '1c824w' }],
  ['polyline', { points: '3.29 7 12 12 20.71 7', key: 'ousv84' }],
  ['line', { x1: '12', x2: '12', y1: '22', y2: '12', key: 'a4e8g8' }],
]);

export default PackagePlus;
