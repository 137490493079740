import createLucideIcon from '../createLucideIcon';

const Snail = createLucideIcon('Snail', [
  [
    'path',
    { d: 'M2 13a6 6 0 1 0 12 0 4 4 0 1 0-8 0 2 2 0 0 0 4 0', key: 'hneq2s' },
  ],
  ['circle', { cx: '10', cy: '13', r: '8', key: '194lz3' }],
  ['path', { d: 'M2 21h12c4.4 0 8-3.6 8-8V7a2 2 0 1 0-4 0v6', key: 'ixqyt7' }],
  ['path', { d: 'M18 3 19.1 5.2', key: '9tjm43' }],
  ['path', { d: 'M22 3 20.9 5.2', key: 'j3odrs' }],
]);

export default Snail;
