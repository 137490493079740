import createLucideIcon from '../createLucideIcon';

const Ratio = createLucideIcon('Ratio', [
  [
    'rect',
    { width: '12', height: '20', x: '6', y: '2', rx: '2', key: '1oxtiu' },
  ],
  [
    'rect',
    { width: '20', height: '12', x: '2', y: '6', rx: '2', key: '9lu3g6' },
  ],
]);

export default Ratio;
