import createLucideIcon from '../createLucideIcon';

const PenSquare = createLucideIcon('PenSquare', [
  [
    'path',
    {
      d: 'M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7',
      key: '1qinfi',
    },
  ],
  [
    'path',
    { d: 'M18.5 2.5a2.12 2.12 0 0 1 3 3L12 15l-4 1 1-4Z', key: 'w2jsv5' },
  ],
]);

export default PenSquare;
