import createLucideIcon from '../createLucideIcon';

const Shell = createLucideIcon('Shell', [
  [
    'path',
    {
      d: 'M14 11a2 2 0 1 1-4 0 4 4 0 0 1 8 0 6 6 0 0 1-12 0 8 8 0 0 1 16 0 10 10 0 1 1-20 0 11.93 11.93 0 0 1 2.42-7.22 2 2 0 1 1 3.16 2.44',
      key: '1cn552',
    },
  ],
]);

export default Shell;
