import createLucideIcon from '../createLucideIcon';

const BadgePoundSterling = createLucideIcon('BadgePoundSterling', [
  [
    'path',
    {
      d: 'M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z',
      key: '3c2336',
    },
  ],
  ['path', { d: 'M8 12h4', key: 'qz6y1c' }],
  ['path', { d: 'M10 16V9.5a2.5 2.5 0 0 1 5 0', key: '3mlbjk' }],
  ['path', { d: 'M8 16h7', key: 'sbedsn' }],
]);

export default BadgePoundSterling;
