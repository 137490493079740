import createLucideIcon from '../createLucideIcon';

const UmbrellaOff = createLucideIcon('UmbrellaOff', [
  ['path', { d: 'M12 2v1', key: '11qlp1' }],
  [
    'path',
    {
      d: 'M15.5 21a1.85 1.85 0 0 1-3.5-1v-8H2a10 10 0 0 1 3.428-6.575',
      key: 'eki10q',
    },
  ],
  ['path', { d: 'M17.5 12H22A10 10 0 0 0 9.004 3.455', key: 'n2ayka' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
]);

export default UmbrellaOff;
