import createLucideIcon from '../createLucideIcon';

const Waypoints = createLucideIcon('Waypoints', [
  ['circle', { cx: '12', cy: '4.5', r: '2.5', key: 'r5ysbb' }],
  ['path', { d: 'm10.2 6.3-3.9 3.9', key: '1nzqf6' }],
  ['circle', { cx: '4.5', cy: '12', r: '2.5', key: 'jydg6v' }],
  ['path', { d: 'M7 12h10', key: 'b7w52i' }],
  ['circle', { cx: '19.5', cy: '12', r: '2.5', key: '1piiel' }],
  ['path', { d: 'm13.8 17.7 3.9-3.9', key: '1wyg1y' }],
  ['circle', { cx: '12', cy: '19.5', r: '2.5', key: '13o1pw' }],
]);

export default Waypoints;
