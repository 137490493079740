import createLucideIcon from '../createLucideIcon';

const ParkingMeter = createLucideIcon('ParkingMeter', [
  ['path', { d: 'M9 9a3 3 0 1 1 6 0', key: 'jdoeu8' }],
  ['path', { d: 'M12 12v3', key: '158kv8' }],
  ['path', { d: 'M11 15h2', key: '199qp6' }],
  [
    'path',
    {
      d: 'M19 9a7 7 0 1 0-13.6 2.3C6.4 14.4 8 19 8 19h8s1.6-4.6 2.6-7.7c.3-.8.4-1.5.4-2.3',
      key: '1l50wn',
    },
  ],
  ['path', { d: 'M12 19v3', key: 'npa21l' }],
]);

export default ParkingMeter;
