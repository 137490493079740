import createLucideIcon from '../createLucideIcon';

const Grid3x3 = createLucideIcon('Grid3x3', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'M3 9h18', key: '1pudct' }],
  ['path', { d: 'M3 15h18', key: '5xshup' }],
  ['path', { d: 'M9 3v18', key: 'fh3hqa' }],
  ['path', { d: 'M15 3v18', key: '14nvp0' }],
]);

export default Grid3x3;
