import createLucideIcon from '../createLucideIcon';

const Caravan = createLucideIcon('Caravan', [
  ['rect', { width: '4', height: '4', x: '2', y: '9', key: '1vcvhd' }],
  ['rect', { width: '4', height: '10', x: '10', y: '9', key: '1b7ev2' }],
  [
    'path',
    {
      d: 'M18 19V9a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v8a2 2 0 0 0 2 2h2',
      key: '19jm3t',
    },
  ],
  ['circle', { cx: '8', cy: '19', r: '2', key: 't8fc5s' }],
  ['path', { d: 'M10 19h12v-2', key: '1yu2qx' }],
]);

export default Caravan;
