import createLucideIcon from '../createLucideIcon';

const ArchiveX = createLucideIcon('ArchiveX', [
  [
    'rect',
    { width: '20', height: '5', x: '2', y: '3', rx: '1', key: '1wp1u1' },
  ],
  ['path', { d: 'M4 8v11a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8', key: '1s80jp' }],
  ['path', { d: 'm9.5 17 5-5', key: 'nakeu6' }],
  ['path', { d: 'm9.5 12 5 5', key: '1hccrj' }],
]);

export default ArchiveX;
