import createLucideIcon from '../createLucideIcon';

const Tally4 = createLucideIcon('Tally4', [
  ['path', { d: 'M4 4v16', key: '6qkkli' }],
  ['path', { d: 'M9 4v16', key: '81ygyz' }],
  ['path', { d: 'M14 4v16', key: '12vmem' }],
  ['path', { d: 'M19 4v16', key: '8ij5ei' }],
]);

export default Tally4;
