import createLucideIcon from '../createLucideIcon';

const Cone = createLucideIcon('Cone', [
  [
    'path',
    { d: 'm20.9 18.55-8-15.98a1 1 0 0 0-1.8 0l-8 15.98', key: '53pte7' },
  ],
  ['ellipse', { cx: '12', cy: '19', rx: '9', ry: '3', key: '1ji25f' }],
]);

export default Cone;
