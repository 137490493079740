import createLucideIcon from '../createLucideIcon';

const FileCog = createLucideIcon('FileCog', [
  ['circle', { cx: '6', cy: '13', r: '3', key: '1z65bp' }],
  ['path', { d: 'm9.7 14.4-.9-.3', key: 'o1luaq' }],
  ['path', { d: 'm3.2 11.9-.9-.3', key: 'qm3zk5' }],
  ['path', { d: 'm4.6 16.7.3-.9', key: '1o0ect' }],
  ['path', { d: 'm7.6 16.7-.4-1', key: '1ym8d1' }],
  ['path', { d: 'm4.8 10.3-.4-1', key: '18q26g' }],
  ['path', { d: 'm2.3 14.6 1-.4', key: '121m88' }],
  ['path', { d: 'm8.7 11.8 1-.4', key: '9meqp2' }],
  ['path', { d: 'm7.4 9.3-.3.9', key: '136qqn' }],
  ['path', { d: 'M14 2v6h6', key: '1kof46' }],
  [
    'path',
    {
      d: 'M4 5.5V4a2 2 0 0 1 2-2h8.5L20 7.5V20a2 2 0 0 1-2 2H6a2 2 0 0 1-2-1.5',
      key: 'xwe04',
    },
  ],
]);

export default FileCog;
