import createLucideIcon from '../createLucideIcon';

const Square = createLucideIcon('Square', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
]);

export default Square;
