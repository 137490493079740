import createLucideIcon from '../createLucideIcon';

const Wallet2 = createLucideIcon('Wallet2', [
  ['path', { d: 'M17 14h.01', key: '7oqj8z' }],
  [
    'path',
    {
      d: 'M7 7h12a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h14',
      key: 'u1rqew',
    },
  ],
]);

export default Wallet2;
