import createLucideIcon from '../createLucideIcon';

const Lollipop = createLucideIcon('Lollipop', [
  ['circle', { cx: '11', cy: '11', r: '8', key: '4ej97u' }],
  ['path', { d: 'm21 21-4.3-4.3', key: '1qie3q' }],
  [
    'path',
    { d: 'M11 11a2 2 0 0 0 4 0 4 4 0 0 0-8 0 6 6 0 0 0 12 0', key: '107gwy' },
  ],
]);

export default Lollipop;
