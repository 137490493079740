import createLucideIcon from '../createLucideIcon';

const FileDigit = createLucideIcon('FileDigit', [
  [
    'rect',
    { width: '4', height: '6', x: '2', y: '12', rx: '2', key: 'jm304g' },
  ],
  ['path', { d: 'M14 2v6h6', key: '1kof46' }],
  [
    'path',
    { d: 'M4 22h14a2 2 0 0 0 2-2V7.5L14.5 2H6a2 2 0 0 0-2 2v4', key: '702lig' },
  ],
  ['path', { d: 'M10 12h2v6', key: '12zw74' }],
  ['path', { d: 'M10 18h4', key: '1ulq68' }],
]);

export default FileDigit;
