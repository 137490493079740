import createLucideIcon from '../createLucideIcon';

const MousePointerSquare = createLucideIcon('MousePointerSquare', [
  [
    'path',
    {
      d: 'M21 11V5a2 2 0 0 0-2-2H5a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h6',
      key: '14rsvq',
    },
  ],
  ['path', { d: 'm12 12 4 10 1.7-4.3L22 16Z', key: '64ilsv' }],
]);

export default MousePointerSquare;
