import createLucideIcon from '../createLucideIcon';

const GalleryHorizontal = createLucideIcon('GalleryHorizontal', [
  ['path', { d: 'M2 3v18', key: 'pzttux' }],
  [
    'rect',
    { width: '12', height: '18', x: '6', y: '3', rx: '2', key: 'btr8bg' },
  ],
  ['path', { d: 'M22 3v18', key: '6jf3v' }],
]);

export default GalleryHorizontal;
