import createLucideIcon from '../createLucideIcon';

const ArchiveRestore = createLucideIcon('ArchiveRestore', [
  [
    'rect',
    { width: '20', height: '5', x: '2', y: '3', rx: '1', key: '1wp1u1' },
  ],
  ['path', { d: 'M4 8v11a2 2 0 0 0 2 2h2', key: 'tvwodi' }],
  ['path', { d: 'M20 8v11a2 2 0 0 1-2 2h-2', key: '1gkqxj' }],
  ['path', { d: 'm9 15 3-3 3 3', key: '1pd0qc' }],
  ['path', { d: 'M12 12v9', key: '192myk' }],
]);

export default ArchiveRestore;
