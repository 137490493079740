import createLucideIcon from '../createLucideIcon';

const ChevronDownSquare = createLucideIcon('ChevronDownSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'm16 10-4 4-4-4', key: '894hmk' }],
]);

export default ChevronDownSquare;
