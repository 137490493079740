import createLucideIcon from '../createLucideIcon';

const PercentCircle = createLucideIcon('PercentCircle', [
  ['circle', { cx: '12', cy: '12', r: '10', key: '1mglay' }],
  ['path', { d: 'm15 9-6 6', key: '1uzhvr' }],
  ['path', { d: 'M9 9h.01', key: '1q5me6' }],
  ['path', { d: 'M15 15h.01', key: 'lqbp3k' }],
]);

export default PercentCircle;
