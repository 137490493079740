import createLucideIcon from '../createLucideIcon';

const TabletSmartphone = createLucideIcon('TabletSmartphone', [
  [
    'rect',
    { width: '10', height: '14', x: '3', y: '8', rx: '2', key: '1vrsiq' },
  ],
  [
    'path',
    {
      d: 'M5 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2h-2.4',
      key: '1j4zmg',
    },
  ],
  ['path', { d: 'M8 18h.01', key: 'lrp35t' }],
]);

export default TabletSmartphone;
