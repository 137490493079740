import createLucideIcon from '../createLucideIcon';

const ArrowsUpFromLine = createLucideIcon('ArrowsUpFromLine', [
  ['path', { d: 'm4 6 3-3 3 3', key: '9aidw8' }],
  ['path', { d: 'M7 17V3', key: '19qxw1' }],
  ['path', { d: 'm14 6 3-3 3 3', key: '6iy689' }],
  ['path', { d: 'M17 17V3', key: 'o0fmgi' }],
  ['path', { d: 'M4 21h16', key: '1h09gz' }],
]);

export default ArrowsUpFromLine;
