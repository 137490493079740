import createLucideIcon from '../createLucideIcon';

const PercentSquare = createLucideIcon('PercentSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'm15 9-6 6', key: '1uzhvr' }],
  ['path', { d: 'M9 9h.01', key: '1q5me6' }],
  ['path', { d: 'M15 15h.01', key: 'lqbp3k' }],
]);

export default PercentSquare;
