import createLucideIcon from '../createLucideIcon';

const Layers2 = createLucideIcon('Layers2', [
  [
    'path',
    {
      d: 'm16.02 12 5.48 3.13a1 1 0 0 1 0 1.74L13 21.74a2 2 0 0 1-2 0l-8.5-4.87a1 1 0 0 1 0-1.74L7.98 12',
      key: '1cuww1',
    },
  ],
  [
    'path',
    {
      d: 'M13 13.74a2 2 0 0 1-2 0L2.5 8.87a1 1 0 0 1 0-1.74L11 2.26a2 2 0 0 1 2 0l8.5 4.87a1 1 0 0 1 0 1.74Z',
      key: 'pdlvxu',
    },
  ],
]);

export default Layers2;
