import createLucideIcon from '../createLucideIcon';

const BugOff = createLucideIcon('BugOff', [
  ['path', { d: 'M15 7.13V6a3 3 0 0 0-5.14-2.1L8 2', key: 'vl8zik' }],
  ['path', { d: 'M14.12 3.88 16 2', key: 'qol33r' }],
  ['path', { d: 'M22 13h-4v-2a4 4 0 0 0-4-4h-1.3', key: '1ou0bd' }],
  ['path', { d: 'M20.97 5c0 2.1-1.6 3.8-3.5 4', key: '18gb23' }],
  ['path', { d: 'm2 2 20 20', key: '1ooewy' }],
  [
    'path',
    { d: 'M7.7 7.7A4 4 0 0 0 6 11v3a6 6 0 0 0 11.13 3.13', key: '1njkjs' },
  ],
  ['path', { d: 'M12 20v-8', key: 'i3yub9' }],
  ['path', { d: 'M6 13H2', key: '82j7cp' }],
  ['path', { d: 'M3 21c0-2.1 1.7-3.9 3.8-4', key: '4p0ekp' }],
]);

export default BugOff;
