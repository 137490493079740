import createLucideIcon from '../createLucideIcon';

const TrainTrack = createLucideIcon('TrainTrack', [
  ['path', { d: 'M2 17 17 2', key: '18b09t' }],
  ['path', { d: 'm2 14 8 8', key: '1gv9hu' }],
  ['path', { d: 'm5 11 8 8', key: '189pqp' }],
  ['path', { d: 'm8 8 8 8', key: '1imecy' }],
  ['path', { d: 'm11 5 8 8', key: 'ummqn6' }],
  ['path', { d: 'm14 2 8 8', key: '1vk7dn' }],
  ['path', { d: 'M7 22 22 7', key: '15mb1i' }],
]);

export default TrainTrack;
