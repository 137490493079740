import createLucideIcon from '../createLucideIcon';

const ArrowDownRightSquare = createLucideIcon('ArrowDownRightSquare', [
  [
    'rect',
    { width: '18', height: '18', x: '3', y: '3', rx: '2', key: 'afitv7' },
  ],
  ['path', { d: 'm8 8 8 8', key: '1imecy' }],
  ['path', { d: 'M16 8v8H8', key: '1lbpgo' }],
]);

export default ArrowDownRightSquare;
