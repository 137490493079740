import createLucideIcon from '../createLucideIcon';

const BadgeRussianRuble = createLucideIcon('BadgeRussianRuble', [
  [
    'path',
    {
      d: 'M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z',
      key: '3c2336',
    },
  ],
  ['path', { d: 'M9 16h5', key: '1syiyw' }],
  ['path', { d: 'M9 12h5a2 2 0 1 0 0-4h-3v9', key: '1ge9c1' }],
]);

export default BadgeRussianRuble;
