import createLucideIcon from '../createLucideIcon';

const Container = createLucideIcon('Container', [
  [
    'path',
    {
      d: 'M22 7.7c0-.6-.4-1.2-.8-1.5l-6.3-3.9a1.72 1.72 0 0 0-1.7 0l-10.3 6c-.5.2-.9.8-.9 1.4v6.6c0 .5.4 1.2.8 1.5l6.3 3.9a1.72 1.72 0 0 0 1.7 0l10.3-6c.5-.3.9-1 .9-1.5Z',
      key: '1t2lqe',
    },
  ],
  ['path', { d: 'M10 21.9V14L2.1 9.1', key: 'o7czzq' }],
  ['path', { d: 'm10 14 11.9-6.9', key: 'zm5e20' }],
  ['path', { d: 'M14 19.8v-8.1', key: '159ecu' }],
  ['path', { d: 'M18 17.5V9.4', key: '11uown' }],
]);

export default Container;
