import createLucideIcon from '../createLucideIcon';

const Route = createLucideIcon('Route', [
  ['circle', { cx: '6', cy: '19', r: '3', key: '1kj8tv' }],
  [
    'path',
    { d: 'M9 19h8.5a3.5 3.5 0 0 0 0-7h-11a3.5 3.5 0 0 1 0-7H15', key: '1d8sl' },
  ],
  ['circle', { cx: '18', cy: '5', r: '3', key: 'gq8acd' }],
]);

export default Route;
