import createLucideIcon from '../createLucideIcon';

const Cuboid = createLucideIcon('Cuboid', [
  [
    'path',
    {
      d: 'm21.12 6.4-6.05-4.06a2 2 0 0 0-2.17-.05L2.95 8.41a2 2 0 0 0-.95 1.7v5.82a2 2 0 0 0 .88 1.66l6.05 4.07a2 2 0 0 0 2.17.05l9.95-6.12a2 2 0 0 0 .95-1.7V8.06a2 2 0 0 0-.88-1.66Z',
      key: '1u2ovd',
    },
  ],
  ['path', { d: 'M10 22v-8L2.25 9.15', key: '11pn4q' }],
  ['path', { d: 'm10 14 11.77-6.87', key: '1kt1wh' }],
]);

export default Cuboid;
