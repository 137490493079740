import createLucideIcon from '../createLucideIcon';

const ScissorsSquareDashedBottom = createLucideIcon(
  'ScissorsSquareDashedBottom',
  [
    [
      'path',
      {
        d: 'M4 22a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2',
        key: '1vzg26',
      },
    ],
    ['path', { d: 'M10 22H8', key: 'euku7a' }],
    ['path', { d: 'M16 22h-2', key: '18d249' }],
    ['circle', { cx: '8', cy: '8', r: '2', key: '14cg06' }],
    ['path', { d: 'M9.414 9.414 12 12', key: 'qz4lzr' }],
    ['path', { d: 'M14.8 14.8 18 18', key: '11flf1' }],
    ['circle', { cx: '8', cy: '16', r: '2', key: '1acxsx' }],
    ['path', { d: 'm18 6-8.586 8.586', key: '11kzk1' }],
  ],
);

export default ScissorsSquareDashedBottom;
