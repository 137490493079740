import createLucideIcon from '../createLucideIcon';

const Gift = createLucideIcon('Gift', [
  ['rect', { x: '3', y: '8', width: '18', height: '4', rx: '1', key: 'bkv52' }],
  ['path', { d: 'M12 8v13', key: '1c76mn' }],
  ['path', { d: 'M19 12v7a2 2 0 0 1-2 2H7a2 2 0 0 1-2-2v-7', key: '6wjy6b' }],
  [
    'path',
    {
      d: 'M7.5 8a2.5 2.5 0 0 1 0-5A4.8 8 0 0 1 12 8a4.8 8 0 0 1 4.5-5 2.5 2.5 0 0 1 0 5',
      key: '1ihvrl',
    },
  ],
]);

export default Gift;
