import createLucideIcon from '../createLucideIcon';

const SignpostBig = createLucideIcon('SignpostBig', [
  ['path', { d: 'M10 9H4L2 7l2-2h6', key: '1hq7x2' }],
  ['path', { d: 'M14 5h6l2 2-2 2h-6', key: 'bv62ej' }],
  ['path', { d: 'M10 22V4a2 2 0 1 1 4 0v18', key: 'eqpcf2' }],
  ['path', { d: 'M8 22h8', key: 'rmew8v' }],
]);

export default SignpostBig;
