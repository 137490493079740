import createLucideIcon from '../createLucideIcon';

const BoomBox = createLucideIcon('BoomBox', [
  ['path', { d: 'M4 9V5a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v4', key: 'vvzvr1' }],
  ['path', { d: 'M8 8v1', key: 'xcqmfk' }],
  ['path', { d: 'M12 8v1', key: '1rj8u4' }],
  ['path', { d: 'M16 8v1', key: '1q12zr' }],
  [
    'rect',
    { width: '20', height: '12', x: '2', y: '9', rx: '2', key: 'igpb89' },
  ],
  ['circle', { cx: '8', cy: '15', r: '2', key: 'fa4a8s' }],
  ['circle', { cx: '16', cy: '15', r: '2', key: '14c3ya' }],
]);

export default BoomBox;
