import createLucideIcon from '../createLucideIcon';

const GalleryHorizontalEnd = createLucideIcon('GalleryHorizontalEnd', [
  ['path', { d: 'M2 7v10', key: 'a2pl2d' }],
  ['path', { d: 'M6 5v14', key: '1kq3d7' }],
  [
    'rect',
    { width: '12', height: '18', x: '10', y: '3', rx: '2', key: '13i7bc' },
  ],
]);

export default GalleryHorizontalEnd;
