import createLucideIcon from '../createLucideIcon';

const TentTree = createLucideIcon('TentTree', [
  ['circle', { cx: '4', cy: '4', r: '2', key: 'bt5ra8' }],
  ['path', { d: 'm14 5 3-3 3 3', key: '1sorif' }],
  ['path', { d: 'm14 10 3-3 3 3', key: '1jyi9h' }],
  ['path', { d: 'M17 14V2', key: '8ymqnk' }],
  ['path', { d: 'M17 14H7l-5 8h20Z', key: '13ar7p' }],
  ['path', { d: 'M8 14v8', key: '1ghmqk' }],
  ['path', { d: 'm9 14 5 8', key: '13pgi6' }],
]);

export default TentTree;
