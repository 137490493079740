import createLucideIcon from '../createLucideIcon';

const Tractor = createLucideIcon('Tractor', [
  ['path', { d: 'M3 4h9l1 7', key: '1ftpo8' }],
  ['path', { d: 'M4 11V4', key: '9ft8pt' }],
  ['path', { d: 'M8 10V4', key: '1y5f7n' }],
  ['path', { d: 'M18 5c-.6 0-1 .4-1 1v5.6', key: '10zbvr' }],
  ['path', { d: 'm10 11 11 .9c.6 0 .9.5.8 1.1l-.8 5h-1', key: '2w242w' }],
  ['circle', { cx: '7', cy: '15', r: '.5', key: 'fbsjqy' }],
  ['circle', { cx: '7', cy: '15', r: '5', key: 'ddtuc' }],
  ['path', { d: 'M16 18h-5', key: 'bq60fd' }],
  ['circle', { cx: '18', cy: '18', r: '2', key: '1emm8v' }],
]);

export default Tractor;
