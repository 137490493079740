import createLucideIcon from '../createLucideIcon';

const BookmarkX = createLucideIcon('BookmarkX', [
  [
    'path',
    { d: 'm19 21-7-4-7 4V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2Z', key: '169p4p' },
  ],
  ['path', { d: 'm14.5 7.5-5 5', key: '3lb6iw' }],
  ['path', { d: 'm9.5 7.5 5 5', key: 'ko136h' }],
]);

export default BookmarkX;
