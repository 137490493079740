import createLucideIcon from '../createLucideIcon';

const ShipWheel = createLucideIcon('ShipWheel', [
  ['circle', { cx: '12', cy: '12', r: '8', key: '46899m' }],
  ['path', { d: 'M12 2v7.5', key: '1e5rl5' }],
  ['path', { d: 'm19 5-5.23 5.23', key: '1ezxxf' }],
  ['path', { d: 'M22 12h-7.5', key: 'le1719' }],
  ['path', { d: 'm19 19-5.23-5.23', key: 'p3fmgn' }],
  ['path', { d: 'M12 14.5V22', key: 'dgcmos' }],
  ['path', { d: 'M10.23 13.77 5 19', key: 'qwopd4' }],
  ['path', { d: 'M9.5 12H2', key: 'r7bup8' }],
  ['path', { d: 'M10.23 10.23 5 5', key: 'k2y7lj' }],
  ['circle', { cx: '12', cy: '12', r: '2.5', key: 'ix0uyj' }],
]);

export default ShipWheel;
