import createLucideIcon from '../createLucideIcon';

const SendToBack = createLucideIcon('SendToBack', [
  [
    'rect',
    { x: '14', y: '14', width: '8', height: '8', rx: '2', key: '1b0bso' },
  ],
  ['rect', { x: '2', y: '2', width: '8', height: '8', rx: '2', key: '1x09vl' }],
  ['path', { d: 'M7 14v1a2 2 0 0 0 2 2h1', key: 'pao6x6' }],
  ['path', { d: 'M14 7h1a2 2 0 0 1 2 2v1', key: '19tdru' }],
]);

export default SendToBack;
