import createLucideIcon from '../createLucideIcon';

const Blocks = createLucideIcon('Blocks', [
  [
    'rect',
    { width: '7', height: '7', x: '14', y: '3', rx: '1', key: '6d4xhi' },
  ],
  [
    'path',
    {
      d: 'M10 21V8a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v12a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-5a1 1 0 0 0-1-1H3',
      key: '1fpvtg',
    },
  ],
]);

export default Blocks;
