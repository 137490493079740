import createLucideIcon from '../createLucideIcon';

const BusFront = createLucideIcon('BusFront', [
  ['path', { d: 'M4 6 2 7', key: '1mqr15' }],
  ['path', { d: 'M10 6h4', key: '1itunk' }],
  ['path', { d: 'm22 7-2-1', key: '1umjhc' }],
  [
    'rect',
    { width: '16', height: '16', x: '4', y: '3', rx: '2', key: '1wxw4b' },
  ],
  ['path', { d: 'M4 11h16', key: 'mpoxn0' }],
  ['path', { d: 'M8 15h.01', key: 'a7atzg' }],
  ['path', { d: 'M16 15h.01', key: 'rnfrdf' }],
  ['path', { d: 'M6 19v2', key: '1loha6' }],
  ['path', { d: 'M18 21v-2', key: 'sqyl04' }],
]);

export default BusFront;
