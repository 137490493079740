import createLucideIcon from '../createLucideIcon';

const Radius = createLucideIcon('Radius', [
  ['path', { d: 'M20.34 17.52a10 10 0 1 0-2.82 2.82', key: 'fydyku' }],
  ['circle', { cx: '19', cy: '19', r: '2', key: '17f5cg' }],
  ['path', { d: 'm13.41 13.41 4.18 4.18', key: '1gqbwc' }],
  ['circle', { cx: '12', cy: '12', r: '2', key: '1c9p78' }],
]);

export default Radius;
