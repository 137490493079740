import createLucideIcon from '../createLucideIcon';

const BadgeCent = createLucideIcon('BadgeCent', [
  [
    'path',
    {
      d: 'M3.85 8.62a4 4 0 0 1 4.78-4.77 4 4 0 0 1 6.74 0 4 4 0 0 1 4.78 4.78 4 4 0 0 1 0 6.74 4 4 0 0 1-4.77 4.78 4 4 0 0 1-6.75 0 4 4 0 0 1-4.78-4.77 4 4 0 0 1 0-6.76Z',
      key: '3c2336',
    },
  ],
  ['path', { d: 'M12 7v10', key: 'jspqdw' }],
  ['path', { d: 'M15.4 10a4 4 0 1 0 0 4', key: '2eqtx8' }],
]);

export default BadgeCent;
